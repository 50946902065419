import React, { useMemo, createContext, useState } from 'react';
import { searchFormFieldsListSaleRoomOrBuildingDefault } from '../constants/saleProperty';
import { PropertySearchModeBasic } from '../types/propertySearchParams/mode';
import { JgdcCode } from '../types/jgdcCode';
import { useSearchFormJgdcCodes } from '../hooks/useSearchFormJgdcCodes';
import { useBool } from '../hooks/util/useBool';
import { SearchablePropertySort } from '../types/propertySearchParams/sort';

export const SaleRoomGlobalContext = createContext(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as {
    // apiへ渡す値系
    saleRoomSearchSelected: Readonly<
      typeof searchFormFieldsListSaleRoomOrBuildingDefault
    >;
    setSaleRoomSearchSelected: (
      searchSelected: Readonly<
        typeof searchFormFieldsListSaleRoomOrBuildingDefault
      >
    ) => void;
    searchFormJgdcCodes: ReadonlyArray<JgdcCode>;
    setFilteredSearchFormJgdcCodes: (
      jgdcCodes: ReadonlyArray<JgdcCode>
    ) => void;
    saleRoomMode: PropertySearchModeBasic;
    setSaleRoomMode: (mode: PropertySearchModeBasic) => void;
    saleRoomOffset: number;
    setSaleRoomOffset: (offset: number) => void;
    saleRoomSelectedSort: SearchablePropertySort;
    setSaleRoomSelectedSort: (sort: SearchablePropertySort) => void;
    // その他
    isSaleRoomListDrawerOpened: boolean;
    openSaleRoomListDrawer: () => void;
    closeSaleRoomListDrawer: () => void;
  }
);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const SaleRoomGlobalContextProvider: React.FC<Props> = ({
  children
}) => {
  const [saleRoomSearchSelected, setSaleRoomSearchSelected] = useState<
    Readonly<typeof searchFormFieldsListSaleRoomOrBuildingDefault>
  >(searchFormFieldsListSaleRoomOrBuildingDefault);
  const [searchFormJgdcCodes, setFilteredSearchFormJgdcCodes] =
    useSearchFormJgdcCodes();
  const [saleRoomMode, setSaleRoomMode] =
    useState<PropertySearchModeBasic>('aggregate');
  const [saleRoomOffset, setSaleRoomOffset] = useState<number>(1);
  const [saleRoomSelectedSort, setSaleRoomSelectedSort] =
    useState<SearchablePropertySort>('last_status_opened_at desc');
  const [
    isSaleRoomListDrawerOpened,
    openSaleRoomListDrawer,
    closeSaleRoomListDrawer
  ] = useBool(true);

  const contextValue = useMemo(
    () => ({
      saleRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      setSaleRoomSearchSelected,
      saleRoomMode,
      setSaleRoomMode,
      saleRoomOffset,
      setSaleRoomOffset,
      saleRoomSelectedSort,
      setSaleRoomSelectedSort,
      isSaleRoomListDrawerOpened,
      openSaleRoomListDrawer,
      closeSaleRoomListDrawer
    }),
    [
      saleRoomSearchSelected,
      searchFormJgdcCodes,
      setFilteredSearchFormJgdcCodes,
      setSaleRoomSearchSelected,
      saleRoomMode,
      setSaleRoomMode,
      saleRoomOffset,
      setSaleRoomOffset,
      saleRoomSelectedSort,
      setSaleRoomSelectedSort,
      isSaleRoomListDrawerOpened,
      openSaleRoomListDrawer,
      closeSaleRoomListDrawer
    ]
  );

  return (
    <SaleRoomGlobalContext.Provider value={contextValue}>
      {children}
    </SaleRoomGlobalContext.Provider>
  );
};
