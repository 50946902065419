import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Login } from '../components/Login';
import { Logout } from '../components/Logout';
import { PrivateRoutes } from './PrivateRoutes';
import { ZishaGyoshakanManagementCompanyRoutes } from './ZishaGyoshakanManagementCompanyRoutes';
import { ZishaGyoshakanBrokerageCompanyRoutes } from './ZishaGyoshakanBrokerageCompanyRoutes';
import { CsvExportPromotionRoutes } from './CsvExportPromotionRoutes';
import { supportHelper, urlHelper } from '../constants/common/url/urlHelpers';
import { NotFoundBrokerageCompanyPage } from '../components/templates/Error/NotFoundBrokerageCompanyPage';
import { ServerErrorBrokerageCompanyPage } from '../components/templates/Error/ServerErrorBrokerageCompanyPage';
import { TooManyRequestsBrokerageCompanyPage } from '../components/templates/Error/TooManyRequestsBrokerageCompanyPage';
import { ServerErrorPage } from '../containers/Error/ServerErrorPage';
import { TooManyRequestsPage } from '../containers/Error/TooManyRequestsPage';
import { ForbiddenPage } from '../containers/Error/ForbiddenPage';
import { ComposedGlobalContextProvider } from '../context/ComposedGlobalContext';
import { CsvExportPromotionContextProvider } from '../context/CsvExportPromotionContext';

/* eslint-disable @typescript-eslint/prefer-readonly-parameter-types */
const ItandiAccountsCallbackPage = React.lazy(() =>
  import('../containers/ItandiAccountsCallbackPage').then((module) => ({
    default: module.ItandiAccountsCallbackPage
  }))
);

const TopPage = React.lazy(() =>
  import('../containers/TopPage').then((module) => ({
    default: module.TopPage
  }))
);

const RentRoomListPage = React.lazy(() =>
  import('../containers/RentRoom/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const RentRoomSearchConditionPage = React.lazy(() =>
  import('../containers/RentRoom/SearchConditionPage').then((module) => ({
    default: module.SearchConditionPage
  }))
);

const FolloweeRentRoomListPage = React.lazy(() =>
  import('../containers/Followee/RentRoom/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const RentRoomDetailPage = React.lazy(() =>
  import('../containers/RentRoom/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const SaleRoomListPage = React.lazy(() =>
  import('../containers/SaleRoom/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const SaleRoomDetailPage = React.lazy(() =>
  import('../containers/SaleRoom/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const RentBusinessPropertyListPage = React.lazy(() =>
  import('../containers/RentBusinessProperty/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const RentBusinessRoomDetailPage = React.lazy(() =>
  import('../containers/RentBusinessRoom/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const RentBusinessBuildingDetailPage = React.lazy(() =>
  import('../containers/RentBusinessBuilding/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const SaleBusinessPropertyListPage = React.lazy(() =>
  import('../containers/SaleBusinessProperty/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const SaleBusinessRoomDetailPage = React.lazy(() =>
  import('../containers/SaleBusinessRoom/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const SaleBusinessBuildingDetailPage = React.lazy(() =>
  import('../containers/SaleBusinessBuilding/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const RentLandListPage = React.lazy(() =>
  import('../containers/RentLand/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const RentLandDetailPage = React.lazy(() =>
  import('../containers/RentLand/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const SaleLandListPage = React.lazy(() =>
  import('../containers/SaleLand/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const SaleLandDetailPage = React.lazy(() =>
  import('../containers/SaleLand/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const RentParkingListPage = React.lazy(() =>
  import('../containers/RentParking/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const RentParkingDetailPage = React.lazy(() =>
  import('../containers/RentParking/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const RealEstateManagementCompanyEntryAvailableProperty = React.lazy(() =>
  import(
    '../containers/RealEstateManagementCompany/EntryAvailableProperty'
  ).then((module) => ({
    default: module.RealEstateManagementCompanyEntryAvailablePropertyPage
  }))
);

const RealEstateManagementCompanyViewingAvailableProperty = React.lazy(() =>
  import(
    '../containers/RealEstateManagementCompany/ViewingAvailableProperty'
  ).then((module) => ({
    default: module.RealEstateManagementCompanyViewingAvailablePropertyPage
  }))
);

const PromotionHistoriesPage = React.lazy(() =>
  import('../containers/Promotion/Histories').then((module) => ({
    default: module.Histories
  }))
);

const PromotionRentMailLogRoomPage = React.lazy(() =>
  import('../containers/Promotion/RentMailLogRoom').then((module) => ({
    default: module.RentMailLogRoom
  }))
);
const PromotionAutoSettingsPage = React.lazy(() =>
  import('../containers/Promotion/AutoSettings').then((module) => ({
    default: module.AutoSettings
  }))
);

const RoomBuildingLogsPage = React.lazy(() =>
  import('../containers/RoomBuilding/Logs').then((module) => ({
    default: module.Logs
  }))
);

const PropertyLogsPage = React.lazy(() =>
  import('../containers/Property/Logs').then((module) => ({
    default: module.Logs
  }))
);

const FollowSettingPage = React.lazy(() =>
  import('../containers/Follow/SettingPage').then((module) => ({
    default: module.SettingPage
  }))
);

const AccountSettingPage = React.lazy(() =>
  import('../containers/Account/SettingPage').then((module) => ({
    default: module.SettingPage
  }))
);

const RoomViewingNewPage = React.lazy(() =>
  import('../containers/RoomViewing/NewPage').then((module) => ({
    default: module.NewPage
  }))
);

const RoomViewingDetailPage = React.lazy(() =>
  import('../containers/RoomViewing/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const RoomViewingListPage = React.lazy(() =>
  import('../containers/RoomViewing/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const ZishaGyoshakanBrokerageCompaniesPage = React.lazy(() =>
  import('../containers/ZishaGyoshakan/BrokerageCompaniesPage').then(
    (module) => ({
      default: module.BrokerageCompaniesPage
    })
  )
);

const ZishaGyoshakanRentRoomListPage = React.lazy(() =>
  import('../containers/ZishaGyoshakan/RentRoom/ListPage').then((module) => ({
    default: module.ListPage
  }))
);

const ZishaGyoshakanRentRoomDetailPage = React.lazy(() =>
  import('../containers/ZishaGyoshakan/RentRoom/DetailPage').then((module) => ({
    default: module.DetailPage
  }))
);

const ZishaGyoshakanRentBusinessPropertyListPage = React.lazy(() =>
  import('../containers/ZishaGyoshakan/RentBusinessProperty/ListPage').then(
    (module) => ({
      default: module.ListPage
    })
  )
);

const ZishaGyoshakanRentBusinessRoomDetailPage = React.lazy(() =>
  import('../containers/ZishaGyoshakan/RentBusinessRoom/DetailPage').then(
    (module) => ({
      default: module.DetailPage
    })
  )
);

const ZishaGyoshakanRentBusinessBuildingDetailPage = React.lazy(() =>
  import('../containers/ZishaGyoshakan/RentBusinessBuilding/DetailPage').then(
    (module) => ({
      default: module.DetailPage
    })
  )
);

const CsvExportPromotionRentRoomsPage = React.lazy(() =>
  import('../containers/CsvExportPromotion/RentRoomsPage').then((module) => ({
    default: module.RentRoomsPage
  }))
);

const CsvExportPromotionUsersPage = React.lazy(() =>
  import('../containers/CsvExportPromotion/UsersPage').then((module) => ({
    default: module.UsersPage
  }))
);

const CsvExportPromotionHistoriesPage = React.lazy(() =>
  import('../containers/CsvExportPromotion/HistoriesPage').then((module) => ({
    default: module.HistoriesPage
  }))
);

// TODO: LoadingPageをimportし、この定数は削除する
export const Loading: JSX.Element = (
  <div className="LoadingArea">
    <div>
      <CircularProgress disableShrink />
    </div>
  </div>
);

export const Routes = (): JSX.Element => (
  <>
    {/* IE専用のエラー画面 */}
    {navigator.userAgent.match(/Trident/) ? (
      <Route
        component={(): null => {
          window.location.replace(supportHelper.endOfSupportIe());
          return null;
        }}
      />
    ) : (
      <Suspense fallback={Loading}>
        <ComposedGlobalContextProvider>
          <Switch>
            <Route exact path={urlHelper.login()}>
              <Login />
            </Route>
            <Route exact path={urlHelper.logout()}>
              <Logout />
            </Route>
            <Route exact path={urlHelper.itandiAccountsCallback()}>
              <ItandiAccountsCallbackPage />
            </Route>
            <Route exact path={urlHelper.root()}>
              <Redirect to={urlHelper.top()} />
            </Route>
            <Route path={urlHelper.error403()}>
              <ForbiddenPage />
            </Route>
            <Route path={urlHelper.error429()}>
              <TooManyRequestsPage />
            </Route>
            <Route path={urlHelper.error500()}>
              <ServerErrorPage />
            </Route>
            <PrivateRoutes>
              <Switch>
                <Route exact path={urlHelper.top()}>
                  <TopPage />
                </Route>
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect from="/rent/search" to={urlHelper.top()} />
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect
                  from="/rent/list/management_company.ids[]=:id"
                  to="/rent_rooms/list/management_company.ids[]=:id"
                />
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect from="/rent/list" to={urlHelper.rentRoomsList()} />
                <Route exact path={urlHelper.rentRoomsList()}>
                  <RentRoomListPage />
                </Route>
                <Redirect
                  from={urlHelper.rentListParams()}
                  to={urlHelper.rentRoomsListParams()}
                />
                <Route exact path={urlHelper.rentRoomsListParams()}>
                  <RentRoomListPage />
                </Route>
                <Route exact path={urlHelper.rentRoomsSearchConditions()}>
                  <RentRoomSearchConditionPage />
                </Route>
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect
                  from={urlHelper.followNewArrivalRooms()}
                  to={urlHelper.followeeRentRoomsList()}
                />
                <Route exact path={urlHelper.followeeRentRoomsList()}>
                  <FolloweeRentRoomListPage />
                </Route>
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect
                  from={urlHelper.rentDetail()}
                  to={urlHelper.rentRoomsDetail()}
                />
                <Route exact path={urlHelper.rentRoomsDetail()}>
                  <RentRoomDetailPage />
                </Route>
                <Route exact path={urlHelper.saleRoomsList()}>
                  <SaleRoomListPage />
                </Route>
                <Route exact path={urlHelper.saleRoomsListParams()}>
                  <SaleRoomListPage />
                </Route>
                <Route exact path={urlHelper.saleRoomsDetail()}>
                  <SaleRoomDetailPage />
                </Route>
                <Route exact path={urlHelper.rentBusinessPropertiesList()}>
                  <RentBusinessPropertyListPage />
                </Route>
                <Route exact path={urlHelper.rentBusinessRoomsDetail()}>
                  <RentBusinessRoomDetailPage />
                </Route>
                <Route exact path={urlHelper.rentBusinessBuildingsDetail()}>
                  <RentBusinessBuildingDetailPage />
                </Route>
                <Route exact path={urlHelper.saleBusinessPropertiesList()}>
                  <SaleBusinessPropertyListPage />
                </Route>
                <Route exact path={urlHelper.saleBusinessRoomsDetail()}>
                  <SaleBusinessRoomDetailPage />
                </Route>
                <Route exact path={urlHelper.saleBusinessBuildingsDetail()}>
                  <SaleBusinessBuildingDetailPage />
                </Route>
                <Route exact path={urlHelper.rentLandsList()}>
                  <RentLandListPage />
                </Route>
                <Route exact path={urlHelper.rentLand()}>
                  <RentLandDetailPage />
                </Route>
                <Route exact path={urlHelper.saleLandsList()}>
                  <SaleLandListPage />
                </Route>
                <Route exact path={urlHelper.saleLand()}>
                  <SaleLandDetailPage />
                </Route>
                <Route exact path={urlHelper.rentParkingsList()}>
                  <RentParkingListPage />
                </Route>
                <Route exact path={urlHelper.rentParking()}>
                  <RentParkingDetailPage />
                </Route>
                <Route
                  exact
                  path={urlHelper.realEstateManagementCompanyEntryAvailableProperty()}
                >
                  <RealEstateManagementCompanyEntryAvailableProperty />
                </Route>
                <Route
                  exact
                  path={urlHelper.realEstateManagementCompanyViewingAvailableProperty()}
                >
                  <RealEstateManagementCompanyViewingAvailableProperty />
                </Route>
                <Route exact path={urlHelper.accountSetting()}>
                  <AccountSettingPage />
                </Route>
                <Route exact path={urlHelper.promotionHistories()}>
                  <PromotionHistoriesPage />
                </Route>
                <Route exact path={urlHelper.promotionRentMailLogRoom()}>
                  <PromotionRentMailLogRoomPage />
                </Route>
                <Route exact path={urlHelper.promotionAutoSettings()}>
                  <PromotionAutoSettingsPage />
                </Route>
                <Route exact path={urlHelper.roomBuildingLogs()}>
                  <RoomBuildingLogsPage />
                </Route>
                <Route exact path={urlHelper.propertyLogs()}>
                  <PropertyLogsPage />
                </Route>
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect from="/sale/search" to={urlHelper.saleRoomsList()} />
                <Route exact path={urlHelper.followSetting()}>
                  <FollowSettingPage />
                </Route>
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect
                  from="/booking/list"
                  to={urlHelper.roomViewingList()}
                />
                <Route exact path={urlHelper.roomViewingList()}>
                  <RoomViewingListPage />
                </Route>
                {/* # TODO: ランディングしているユーザがほぼいないことを確認したら削除する */}
                <Redirect
                  from="/booking/new/rent/:id"
                  to={urlHelper.roomViewingNew()}
                />
                <Route exact path={urlHelper.roomViewingNew()}>
                  <RoomViewingNewPage />
                </Route>
                <Route exact path={urlHelper.roomViewingDetailFromNew()}>
                  <RoomViewingDetailPage />
                </Route>
                <Route exact path={urlHelper.roomViewingDetail()}>
                  <RoomViewingDetailPage />
                </Route>
                <Route
                  exact
                  path={urlHelper.zishaGyoshakanBrokerageCompanies()}
                >
                  <ZishaGyoshakanManagementCompanyRoutes>
                    <ZishaGyoshakanBrokerageCompaniesPage />
                  </ZishaGyoshakanManagementCompanyRoutes>
                </Route>
                <Route exact path={urlHelper.zishaGyoshakanRentRoomsList()}>
                  <ZishaGyoshakanBrokerageCompanyRoutes>
                    <ZishaGyoshakanRentRoomListPage />
                  </ZishaGyoshakanBrokerageCompanyRoutes>
                </Route>
                <Route exact path={urlHelper.zishaGyoshakanRentRoomsDetail()}>
                  <ZishaGyoshakanBrokerageCompanyRoutes>
                    <ZishaGyoshakanRentRoomDetailPage />
                  </ZishaGyoshakanBrokerageCompanyRoutes>
                </Route>
                <Route
                  exact
                  path={urlHelper.zishaGyoshakanRentBusinessPropertiesList()}
                >
                  <ZishaGyoshakanBrokerageCompanyRoutes>
                    <ZishaGyoshakanRentBusinessPropertyListPage />
                  </ZishaGyoshakanBrokerageCompanyRoutes>
                </Route>
                <Route
                  exact
                  path={urlHelper.zishaGyoshakanRentBusinessRoomsDetail()}
                >
                  <ZishaGyoshakanBrokerageCompanyRoutes>
                    <ZishaGyoshakanRentBusinessRoomDetailPage />
                  </ZishaGyoshakanBrokerageCompanyRoutes>
                </Route>
                <Route
                  exact
                  path={urlHelper.zishaGyoshakanRentBusinessBuildingsDetail()}
                >
                  <ZishaGyoshakanBrokerageCompanyRoutes>
                    <ZishaGyoshakanRentBusinessBuildingDetailPage />
                  </ZishaGyoshakanBrokerageCompanyRoutes>
                </Route>
                <Route exact path={urlHelper.csvExportPromotionRooms()}>
                  <CsvExportPromotionRoutes>
                    <CsvExportPromotionContextProvider>
                      <CsvExportPromotionRentRoomsPage />
                    </CsvExportPromotionContextProvider>
                  </CsvExportPromotionRoutes>
                </Route>
                <Route exact path={urlHelper.csvExportPromotionUsers()}>
                  <CsvExportPromotionRoutes>
                    <CsvExportPromotionContextProvider>
                      <CsvExportPromotionUsersPage />
                    </CsvExportPromotionContextProvider>
                  </CsvExportPromotionRoutes>
                </Route>
                <Route exact path={urlHelper.csvExportPromotionHistories()}>
                  <CsvExportPromotionRoutes>
                    <CsvExportPromotionHistoriesPage />
                  </CsvExportPromotionRoutes>
                </Route>
                <Route path={urlHelper.error429Private()}>
                  <TooManyRequestsBrokerageCompanyPage />
                </Route>
                <Route path={urlHelper.error()}>
                  <ServerErrorBrokerageCompanyPage />
                </Route>
                <Route path="*">
                  <NotFoundBrokerageCompanyPage />
                </Route>
              </Switch>
            </PrivateRoutes>
            <Route path="*">
              <NotFoundBrokerageCompanyPage />
            </Route>
          </Switch>
        </ComposedGlobalContextProvider>
      </Suspense>
    )}
  </>
);
